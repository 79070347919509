import styled from 'styled-components';

export const StyledSignsSection = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.concrete};

  .dp-signs-section {
    padding: 60px;
    gap: 80px;
    max-width: 1300px;
    margin: 0 auto;
    flex-direction: column;

    &__header {
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    &__cards {
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      gap: 24px;

      &__card {
        width: 350px;
        min-height: 645px;

        .ant-card-body {
          padding: 16px;
          align-items: center;
          justify-content: center;
        }

        &__body {
          flex-direction: column;
          gap: 16px;

          &__title {
            text-align: center;
            align-items: center;
            justify-content: center;
          }

          &__content {
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 16px;

            p {
              text-align: justify;
            }
          }
        }
      }
    }

    @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
      gap: 40px;
      padding: 40px 24px;

      &__cards {
        &__card {
          min-height: 0;

          .ant-card-cover {
            height: auto;
            margin: 0;

            .ant-image {
              max-width: calc(100vw - 50px);
              max-height: 100%;

              img {
                max-width: 100%;
              }
            }
          }
        }
      }
    }

    @media (max-width: ${({ theme }) => theme.responsive.small}) {
      &__cards {
        &__card {
          width: 100%;
        }
      }
    }
  }
`;
