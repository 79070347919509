export const variables = {
  // ---------------------------------------------------------------------------
  // Colors
  // ---------------------------------------------------------------------------
  colors: {
    white: '#ffffff',
    black: '#000000',
    goldenDream: '#F1E22D',
    hibiscus: '#BF2F76',
    curiousBlue: '#2191CE',
    concrete: '#F2F2F2',
  },

  // ---------------------------------------------------------------------------
  // Responsive rules
  // ---------------------------------------------------------------------------
  responsive: {
    highres2: '2000px',
    highres: '1440px',
    desktop: '1200px',
    tablet: '992px',
    mobile: '768px',
    small: '400px',
  },
};
