// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from 'react';

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { MainSection } from '@Components/sections/main-section';
import { WhoWeAreSection } from '@Components/sections/who-we-are-section';
import { ServicesSection } from '@Components/sections/services-section';
import { ClientsSection } from '@Components/sections/clients-section';
import { LocationSection } from '@Components/sections/location-section';
import { ProductsSection } from '@Components/sections/products-section';
import { SignsSection } from '@Components/sections/signs-section';
import { WorksSection } from '@Components/sections/works-section';
import { ContactSection } from '@Components/sections/contact-section';
import { FooterSection } from '@Components/sections/footer-section';

// -------------------------------------------------------------------------------
// Styles and Assets
// -------------------------------------------------------------------------------
import { StyledHomePage } from './style';

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const HomePage = () => {
  return (
    <StyledHomePage>
      <div className="dp-home-page">
        <div className="dp-home-page__main-section">
          <MainSection />
        </div>
        <div className="dp-home-page__secondary-section">
          <WhoWeAreSection />
        </div>
        <div className="dp-home-page__secondary-section">
          <ServicesSection />
        </div>
        <div className="dp-home-page__secondary-section">
          <ClientsSection />
        </div>
        <div className="dp-home-page__secondary-section">
          <LocationSection />
        </div>
        <div className="dp-home-page__secondary-section">
          <ProductsSection />
        </div>
        <div className="dp-home-page__secondary-section">
          <SignsSection />
        </div>
        <div className="dp-home-page__secondary-section">
          <WorksSection />
        </div>
        <div className="dp-home-page__secondary-section">
          <ContactSection />
        </div>
        <div className="dp-home-page__secondary-section">
          <FooterSection />
        </div>
      </div>
    </StyledHomePage>
  );
};

export default HomePage;
