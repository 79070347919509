// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------
import { setMainMenuOpen } from '@Store/slices/app-state/app-state-slice';
import { selectMainMenuOpen } from '@Store/selectors';

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { HeaderLogoIcon } from '@Components/common/icons/header-logo-icon';

// -------------------------------------------------------------------------------
// Styles and Assets
// -------------------------------------------------------------------------------
import { bem } from '@Helpers/bem';
import { StyledHeader } from './style';

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const Header = () => {
  const dispatch = useDispatch();
  const menuOpen = useSelector(selectMainMenuOpen);

  const onMenuIconClick = useCallback(() => {
    dispatch(setMainMenuOpen(!menuOpen));
  }, [dispatch, menuOpen]);

  return (
    <StyledHeader>
      <div className="dp-header">
        <div className="dp-header__logo">
          <HeaderLogoIcon />
        </div>
        <div className={bem`dp-header__menu--${{ open: menuOpen }}`}>
          <button
            title="menu-button"
            type="button"
            className={bem`dp-header__menu__icon--${{ open: menuOpen }}`}
            onClick={onMenuIconClick}
          >
            <div className={bem`dp-header__menu__icon__bar1--${{ open: menuOpen }}`} />
            <div className={bem`dp-header__menu__icon__bar2--${{ open: menuOpen }}`} />
            <div className={bem`dp-header__menu__icon__bar3--${{ open: menuOpen }}`} />
          </button>
        </div>
      </div>
    </StyledHeader>
  );
};

export default Header;
