import { createSlice } from '@reduxjs/toolkit';

export const appStateSlice = createSlice({
  name: 'app-state',
  initialState: {
    mainMenuOpen: false,
  },
  reducers: {
    setMainMenuOpen: (state, { payload }) => {
      state.mainMenuOpen = payload;
    },
  },
});

export const { setMainMenuOpen } = appStateSlice.actions;

export default appStateSlice.reducer;
