// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Typed from 'typed.js';

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------
import { selectMainMenuOpen } from '@Store/selectors';

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { Header } from '@Components/common/header';
import { MainMenu } from '@Components/common/main-menu';
import { ScrollDown } from '@Components/common/scroll-down';

// -------------------------------------------------------------------------------
// Styles and Assets
// -------------------------------------------------------------------------------
import MainBg from '@Theme/assets/main-bg.webp';
import { bem } from '@Helpers/bem';
import { StyledMainSection } from './style';

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const UsersPage = () => {
  const el = useRef(null);
  const typed = useRef(null);
  const menuOpen = useSelector(selectMainMenuOpen);

  useEffect(() => {
    const options = {
      strings: ['<h1>Digital Print</h1>', '<h1>Publicidad</h1>', '<h1>Impresión</h1>'],
      typeSpeed: 50,
      backSpeed: 50,
      loop: true,
    };

    typed.current = new Typed(el.current, options);

    return () => {
      typed.current.destroy();
    };
  }, []);

  return (
    <StyledMainSection>
      <div className="dp-main-section">
        <Header />
        <div className="dp-main-section__inner">
          <div className={bem`dp-main-section__inner__main-menu--${{ open: menuOpen }}`}>
            <MainMenu />
          </div>
          <div className={bem`dp-main-section__inner__content--${{ open: menuOpen }}`}>
            <div className={bem`dp-main-section__inner__content__title--${{ open: menuOpen }}`}>
              <div className="dp-main-section__inner__content__title__heading">
                <span style={{ whiteSpace: 'pre' }} ref={el} />
              </div>
              <div className="dp-main-section__inner__content__title__scroll">
                <ScrollDown />
              </div>
            </div>
            <img className="dp-main-section__inner__content__bg-image" src={MainBg} alt="main bg" />
          </div>
        </div>
      </div>
    </StyledMainSection>
  );
};

export default UsersPage;
