// -----------------------------------------------------------------------
// Libraries
// -----------------------------------------------------------------------
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

// -----------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------
import { store } from '@Store';

// -----------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------
import { HomePage } from '@Pages/home-page';

// -----------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------
import { Theme } from '@Theme';
import reportWebVitals from './reportWebVitals';
import '@Theme/styles/styles.scss';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

// -----------------------------------------------------------------------
// Variables
// -----------------------------------------------------------------------
const container = document.getElementById('root');
const root = createRoot(container);

// -----------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Theme>
        <HomePage />
      </Theme>
    </Provider>
  </React.StrictMode>
);

// -----------------------------------------------------------------------
// Vitals
// -----------------------------------------------------------------------
reportWebVitals(console.log);
