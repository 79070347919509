// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from 'react';
import Lottie from 'react-lottie';
import { Card } from 'antd';

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { Heading1, Paragraph2 } from '@Theme';

// -------------------------------------------------------------------------------
// Styles and Assets
// -------------------------------------------------------------------------------
import * as printerBlack from '@Helpers/animations/printer-black.json';
import { StyledWhoWeAreSection } from './style';

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const WhoWeAreSection = () => {
  return (
    <StyledWhoWeAreSection>
      <div id="who-we-are-section" className="dp-who-we-are-section">
        <Card className="dp-who-we-are-section__card" hoverable>
          <div className="dp-who-we-are-section__card__animation">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: printerBlack,
              }}
            />
          </div>
          <div className="dp-who-we-are-section__card__content">
            <Heading1>Quienes Somos</Heading1>
            <div className="dp-who-we-are-section__card__content__text">
              <Paragraph2>
                Digital Print, grupo empresarial publicitario que provee la mejor publicidad física para su
                emprendimiento. Formado por profesionales que construyen relaciones de confianza con nuestros clientes
                para convertirse en socios estratégicos. Aportamos resultados, soluciones y experiencia frente a
                problemas, objetivos y retos. Consideramos vital, potenciar las oportunidades de negocio e imagen de
                nuestros clientes a través de estrategias promocionales.
              </Paragraph2>
            </div>
          </div>
        </Card>
      </div>
    </StyledWhoWeAreSection>
  );
};

export default WhoWeAreSection;
