import styled from 'styled-components';

export const StyledLocationSection = styled.div`
  width: 100%;

  .dp-location-section {
    width: 100%;
    align-items: center;
    justify-content: center;

    &__header {
      width: 50%;
      height: 200px;
      align-items: center;
      justify-content: center;
      position: relative;

      &__animation {
        width: 200px;
        height: 200px;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
      }
    }

    &__body {
      width: 50%;
      height: 600px;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      padding: 32px 32px 32px 0;

      &__card {
        width: 100%;
        height: 100%;

        .ant-card-body {
          width: 100%;
          height: 100%;
          padding: 0;
        }
      }

      #map {
        width: 100%;
        height: 100%;
      }
    }

    @media (max-width: ${({ theme }) => theme.responsive.highres2}) {
      &__header {
        width: 40%;
      }

      &__body {
        width: 60%;
      }
    }

    @media (max-width: ${({ theme }) => theme.responsive.highres}) {
      &__header {
        width: 30%;
      }

      &__body {
        width: 70%;
      }
    }

    @media (max-width: ${({ theme }) => theme.responsive.tablet}) {
      flex-direction: column;

      &__header {
        width: 100%;
        padding-top: 40px;
      }

      &__body {
        width: calc(100% - 32px);
        padding: 16px;
      }
    }

    @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
      &__body {
        width: calc(100% - 16px);
        padding: 8px;
      }
    }
  }
`;
