import styled from 'styled-components';

export const StyledProductsSection = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.concrete};

  .dp-products-section {
    padding: 60px;
    gap: 80px;
    max-width: 1200px;
    margin: 0 auto;
    flex-direction: column;

    &__header {
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    &__body {
      max-width: calc(100vw - 130px);

      .swiper {
        width: 100%;
        display: block;

        .swiper-wrapper {
          .swiper-slide {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 300px;
            height: 300px;

            img {
              display: block;
              width: 300px;
              height: auto;
            }
          }
        }
      }
    }

    @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
      padding: 40px 0px;
      gap: 32px;

      &__header {
        padding: 0 16px;
      }

      &__body {
        max-width: calc(100vw - 10px);
      }
    }

    @media (max-width: ${({ theme }) => theme.responsive.small}) {
      &__body {
        .swiper {
          .swiper-wrapper {
            .swiper-slide {
              width: 200px;
              height: 200px;

              img {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
`;
