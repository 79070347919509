// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from 'react';

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { Paragraph3 } from '@Theme';

// -------------------------------------------------------------------------------
// Styles and Assets
// -------------------------------------------------------------------------------
import { StyledList } from './style';

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const List = ({ items }) => {
  return (
    <StyledList>
      <ul className="dp-list">
        {items.map((item, index) => (
          <li key={`list-${item}-${index}`} className="dp-list__li">
            <Paragraph3>{item}</Paragraph3>
          </li>
        ))}
      </ul>
    </StyledList>
  );
};

export default List;
