// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Autoplay } from 'swiper';

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { Heading1 } from '@Theme/theme-provider/typography';

// -------------------------------------------------------------------------------
// Styles and Assets
// -------------------------------------------------------------------------------
import schoolImage from '@Theme/assets/images/school.webp';
import officeImage from '@Theme/assets/images/office.webp';
import textilImage from '@Theme/assets/images/textil.webp';
import ceramicImage from '@Theme/assets/images/ceramic.webp';
import { StyledProductsSection } from './style';

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const ProductsSection = () => {
  return (
    <StyledProductsSection>
      <div id="products-section" className="dp-products-section">
        <div className="dp-products-section__header">
          <Heading1>Nuestros Productos</Heading1>
        </div>
        <div className="dp-products-section__body">
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={'auto'}
            freeMode={true}
            speed={2000}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            loop={true}
            loopFillGroupWithBlank={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            modules={[EffectCoverflow, Autoplay]}
            className="dp-products-section__body__swiper"
          >
            <SwiperSlide>
              <img src={schoolImage} width={300} height={300} alt="school" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={officeImage} width={300} height={300} alt="office" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={textilImage} width={300} height={300} alt="textil" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={ceramicImage} width={300} height={300} alt="ceramic" />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </StyledProductsSection>
  );
};

export default ProductsSection;
