// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useState } from 'react';
import Lottie from 'react-lottie';
import emailjs from '@emailjs/browser';
import { MailOutlined } from '@ant-design/icons';
import { Button, Form, Input, notification } from 'antd';

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { Heading1 } from '@Theme/theme-provider/typography';

// -------------------------------------------------------------------------------
// Styles and Assets
// -------------------------------------------------------------------------------
import bem from '@Helpers/bem/bem';
import * as contactUs from '@Helpers/animations/contact-us.json';
import { StyledContactSection } from './style';

const { TextArea } = Input;

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const ContactSection = () => {
  const [api, contextHolder] = notification.useNotification();
  const [formSent, setFormSent] = useState(false);

  const onFinish = ({ name, email, message }) => {
    emailjs
      .send(
        process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
        process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID,
        { name, email, message },
        process.env.REACT_APP_EMAIL_JS_USER_ID
      )
      .then(
        () => {
          api['success']({
            message: 'Mensaje Enviado',
            description: 'Gracias por contactarnos, pronto nos pondremos en contacto.',
            placement: 'top',
          });
          setFormSent(true);
        },
        (error) => {
          if (error) {
            api['error']({
              message: 'Mensaje No Enviado',
              description: 'Estamos teniendo problemas tecnicos, pronto los solucionaremos.',
              placement: 'top',
            });
          }
        }
      );
  };

  return (
    <StyledContactSection>
      {contextHolder}
      <div id="contact-section" className="dp-contact-section">
        <div className="dp-contact-section__header">
          <Heading1>Contacto</Heading1>
        </div>
        <div className="dp-contact-section__body">
          <div className={bem`dp-contact-section__body__animation--${{ formSent }}`}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: contactUs,
              }}
            />
          </div>
          {!formSent && (
            <div className="dp-contact-section__body__form">
              <Form onFinish={onFinish} requiredMark={false} layout="vertical">
                <Form.Item label="Nombre" name="name" rules={[{ required: true, message: 'Su nombre es necesario.' }]}>
                  <Input size="large" />
                </Form.Item>

                <Form.Item label="Correo" name="email" rules={[{ required: true, message: 'Su correo es necesario.' }]}>
                  <Input size="large" />
                </Form.Item>

                <Form.Item label="Mensaje" name="message">
                  <TextArea rows={4} />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" icon={<MailOutlined />}>
                    Enviar
                  </Button>
                </Form.Item>
              </Form>
            </div>
          )}
        </div>
      </div>
    </StyledContactSection>
  );
};

export default ContactSection;
