// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from 'react';

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { Heading1 } from '@Theme';

// -------------------------------------------------------------------------------
// Styles and Assets
// -------------------------------------------------------------------------------
import bem from '@Helpers/bem/bem';
import logoLa29 from '@Theme/assets/logos/la-29-logo.webp';
import quitoTenis from '@Theme/assets/logos/quito-tenis.webp';
import riverMall from '@Theme/assets/logos/river-mall.webp';
import panamericana from '@Theme/assets/logos/panamericana.webp';
import aerogal from '@Theme/assets/logos/aerogal.svg';
import repsol from '@Theme/assets/logos/repsol.svg';
import iess from '@Theme/assets/logos/iess.svg';
import petzl from '@Theme/assets/logos/petzl.svg';
import cargoUnion from '@Theme/assets/logos/cargo-union.svg';
import ringersGloves from '@Theme/assets/logos/ringers-gloves.svg';
import nipro from '@Theme/assets/logos/nipro.svg';
import alianzaMinas from '@Theme/assets/logos/alianza-minas.svg';
import politecnica from '@Theme/assets/logos/politecnica.svg';
import ministerioSaludPublica from '@Theme/assets/logos/ministerio-salud-publica.svg';
import perfecturaPichincha from '@Theme/assets/logos/perfectura-pichincha.svg';
import oresa from '@Theme/assets/logos/oresa.svg';
import vozandes from '@Theme/assets/logos/vozandes.svg';
import logoBoehringerIngelheim from '@Theme/assets/logos/boehringer-ingelheim-logo.svg';
import { StyledClientsSection } from './style';

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const ClientsSection = () => {
  const clients = [
    {
      image: politecnica,
      name: 'Politécnica',
    },
    {
      image: quitoTenis,
      name: 'Quito Tenis y Golf  Club',
      hideMobile: true,
    },
    {
      image: logoLa29,
      name: 'La 29 de Octubre',
    },
    {
      image: alianzaMinas,
      name: 'Alianza Minas',
    },
    {
      image: panamericana,
      name: 'Panamericana',
    },
    {
      image: oresa,
      name: 'Oresa',
      hideMobile: true,
    },
    {
      image: nipro,
      name: 'Nipro',
    },
    {
      image: aerogal,
      name: 'Aerogal',
    },
    {
      image: petzl,
      name: 'Petzl',
    },
    {
      image: repsol,
      name: 'Repsol',
      hideMobile: true,
    },
    {
      image: ringersGloves,
      name: 'Ringers Gloves',
      hideMobile: true,
    },
    {
      image: logoBoehringerIngelheim,
      name: 'Boehringer Ingelheim',
      hideMobile: true,
    },
    {
      image: cargoUnion,
      name: 'Cargo Unión',
    },
    {
      image: vozandes,
      name: 'Vozandes',
    },
    {
      image: iess,
      name: 'I.E.S.S.',
    },
    {
      image: perfecturaPichincha,
      name: 'Perfectura Pichincha',
      hideMobile: true,
    },
    {
      image: riverMall,
      name: 'River Mall',
      hideMobile: true,
    },
    {
      image: ministerioSaludPublica,
      name: 'Ministerio Salud Pública',
    },
  ];

  return (
    <StyledClientsSection>
      <div id="clients-section" className="dp-clients-section">
        <div className="dp-clients-section__header">
          <Heading1>Nuestros Clientes</Heading1>
        </div>
        <div className="dp-clients-section__body">
          {clients.map(({ image, name, hideMobile }, index) => (
            <div key={`client-${name}-${index}`} className={bem`dp-clients-section__body__card--${{ hideMobile }}`}>
              <img
                className="dp-clients-section__body__card__image"
                width={200}
                height={200}
                alt="client"
                src={image}
              />
            </div>
          ))}
        </div>
      </div>
    </StyledClientsSection>
  );
};

export default ClientsSection;
