import styled from 'styled-components';

export const Heading1 = styled.h1`
  font-family: 'roboto';
  font-weight: 700;
  font-size: 54px;
  line-height: normal;
  color: ${({ theme }) => theme.colors.hibiscus};

  @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
    font-size: 40px;
  }
`;

export const Heading2 = styled.p`
  font-family: 'roboto';
  font-weight: 700;
  font-size: 20px;
  line-height: normal;
  color: ${({ theme }) => theme.colors.hibiscus};
`;

export const Paragraph1 = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: normal;
  color: ${({ theme }) => theme.colors.black};
`;

export const Paragraph2 = styled.p`
  font-weight: 300;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.black};
`;

export const Paragraph3 = styled.p`
  font-family: 'roboto';
  font-weight: 300;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black};
`;

export const Paragraph4 = styled.p`
  font-family: 'roboto';
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.black};
`;

export const Paragraph5 = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: normal;
  color: ${({ theme }) => theme.colors.white};
`;

export const Paragraph6 = styled.p`
  font-family: 'roboto';
  font-weight: 700;
  font-size: 15px;
  line-height: normal;
  color: ${({ theme }) => theme.colors.white};
`;
