import styled from 'styled-components';

export const StyledMainMenu = styled.div`
  .dp-main-menu {
    color: ${({ theme }) => theme.colors.black};
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__link {
      background-image: linear-gradient(
        to right,
        ${({ theme }) => theme.colors.hibiscus},
        ${({ theme }) => theme.colors.hibiscus} 50%,
        ${({ theme }) => theme.colors.black} 50%
      );
      background-size: 200% 100%;
      background-position: -100%;
      padding: 5px 0;
      cursor: pointer;
      width: fit-content;
      display: inline-block;
      position: relative;
      background-clip: unset;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      transition: all 0.3s ease-in-out;
      border: none;

      &:before {
        content: '';
        background: ${({ theme }) => theme.colors.hibiscus};
        display: block;
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 0;
        height: 3px;
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        background-position: 0;

        &:before {
          width: 100%;
        }
      }
    }

    @media screen and (orientation: landscape) and (max-width: ${({ theme }) => theme.responsive.tablet}) {
      gap: 10px;

      &__link {
        p {
          font-size: 16px;
        }
      }
    }
  }
`;
