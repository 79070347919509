import styled from 'styled-components';

export const StyledMainSection = styled.div`
  width: 100%;

  .dp-main-section {
    width: 100%;
    flex-direction: column;

    &__inner {
      width: 100%;
      height: 100%;
      flex-direction: column;
      overflow: hidden;
      position: relative;
      perspective: 800px;

      &__main-menu {
        position: absolute;
        top: calc(50% - 150px);
        width: 100%;
        opacity: 0;
        transform: translate(200%, 0);
        transition: transform 0.8s, opacity 1s;

        &--open {
          transform: translate(50%, 0);
          opacity: 1;
        }
      }

      &__content {
        width: 100%;
        height: 100%;
        transition: transform 0.4s;
        transform-origin: 50% 50%;
        transform-style: preserve-3d;
        position: relative;
        transform: rotateY(0deg) translateX(0) translateZ(0);

        &__title,
        &__bg-image {
          position: absolute;
          width: 100vw;
          min-height: calc(100vh - 100px);
          left: 0;
          top: 0;
        }

        &__title {
          align-items: center;
          justify-content: center;
          background: rgba(0, 0, 0, 0.8);
          z-index: 2;

          &__heading,
          h1 {
            font-weight: 700;
            font-size: 70px;
            padding-bottom: 44px;
            font-family: 'Roboto';
            display: inline;
            color: ${({ theme }) => theme.colors.hibiscus};
          }

          &__scroll {
            position: absolute;
            left: 50%;
            margin-left: -30px;
            bottom: 44px;
          }

          &--open > * {
            display: none;
          }
        }

        &__bg-image {
          object-fit: cover;
          z-index: 1;
        }

        &--open {
          transform: rotateY(45deg) translateX(-50%) translateZ(-300px);
        }
      }
    }

    @media screen and (orientation: landscape) and (max-width: ${({ theme }) => theme.responsive.tablet}) {
      &__inner {
        &__main-menu {
          top: calc(50% - 90px);
        }

        &__content {
          &__title {
            &__heading {
              margin-bottom: 64px;
            }

            &__scroll {
              bottom: 24px;
            }
          }
        }
      }
    }

    @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
      &__inner {
        perspective: 300px;

        &__main-menu {
          &--open {
            transform: translate(calc(50% - 30px), 0);
          }
        }

        &__content {
          &__title {
            &__heading {
              font-size: 44px;
            }
          }

          &--open {
            transform: rotateY(45deg) translateX(-50%) translateZ(-160px);
          }
        }
      }
    }

    @media (max-width: ${({ theme }) => theme.responsive.small}) {
      &__inner {
        perspective: 200px;

        &__main-menu {
          &--open {
            transform: translate(calc(50% - 60px), 0);
          }
        }

        &__content {
          &__title {
            &__heading {
              font-size: 30px;
            }
          }

          &--open {
            transform: rotateY(45deg) translateX(-50%) translateZ(-200px);
          }
        }
      }
    }
  }
`;
