// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from 'react';

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { Paragraph5, Paragraph6 } from '@Theme/theme-provider/typography';

// -------------------------------------------------------------------------------
// Styles and Assets
// -------------------------------------------------------------------------------
import { StyledFooterSection } from './style';

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const FooterSection = () => {
  return (
    <StyledFooterSection>
      <div id="footer-section" className="dp-footer-section">
        <div className="dp-footer-section__section">
          <div className="dp-footer-section__section__contact">
            <Paragraph6>Telefono: 0978935045</Paragraph6>
            <Paragraph6>Telefono: 0983306846</Paragraph6>
            <Paragraph6>Correo: digital.print.sa.ec@gmail.com</Paragraph6>
            <Paragraph6>Dirección: Cañaris S9 - 136, Quito 170305</Paragraph6>
          </div>
        </div>
        <div className="dp-footer-section__section">
          <nav className="dp-footer-section__section__nav">
            <a href="#who-we-are-section" className="dp-footer-section__section__nav__link">
              <Paragraph5>Quienes Somos</Paragraph5>
            </a>
            <a href="#services-section" className="dp-footer-section__section__nav__link">
              <Paragraph5>Servicios</Paragraph5>
            </a>
            <a href="#clients-section" className="dp-footer-section__section__nav__link">
              <Paragraph5>Clientes</Paragraph5>
            </a>
            <a href="#products-section" className="dp-footer-section__section__nav__link">
              <Paragraph5>Productos</Paragraph5>
            </a>
            <a href="#works-section" className="dp-footer-section__section__nav__link">
              <Paragraph5>Trabajos</Paragraph5>
            </a>
            <a href="#contact-section" className="dp-footer-section__section__nav__link">
              <Paragraph5>Contacto</Paragraph5>
            </a>
          </nav>
        </div>
        <div className="dp-footer-section__section">
          <div className="dp-footer-section__section__contact">
            <Paragraph6>Creado por: Digital Print Group</Paragraph6>
          </div>
        </div>
      </div>
    </StyledFooterSection>
  );
};

export default FooterSection;
