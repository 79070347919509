import styled from 'styled-components';

export const StyledWhoWeAreSection = styled.div`
  width: 100%;

  .dp-who-we-are-section {
    width: 100%;
    padding: 200px 120px 120px 120px;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: ${({ theme }) => theme.colors.concrete};

    &__card {
      width: 90%;
      max-width: 1200px;

      .ant-card-body {
        width: 100%;
        padding: 0;
      }

      &__animation {
        position: absolute;
        width: 400px;
        height: 400px;
        top: -200px;
        left: -200px;
      }

      &__content {
        padding: 200px;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 24px;
        text-align: center;

        &__text {
          text-align: center;
        }
      }
    }

    @media (max-width: ${({ theme }) => theme.responsive.desktop}) {
      &__card {
        &__content {
          padding: 200px 60px 60px 60px;
        }
      }
    }

    @media (max-width: ${({ theme }) => theme.responsive.tablet}) {
      padding: 200px 24px 24px 24px;

      &__card {
        width: calc(100% - 16px);

        &__animation {
          left: calc(50% - 200px);
        }

        &__content {
          padding: 200px 36px 56px 36px;
        }
      }
    }

    @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
      padding: 120px 12px 24px 12px;

      &__card {
        &__animation {
          width: 200px;
          height: 200px;
          top: -100px;
          left: calc(50% - 100px);
        }

        &__content {
          padding: 110px 24px 44px 24px;

          &__text {
            p {
              font-size: 18px;
            }
          }
        }
      }
    }

    @media (max-width: ${({ theme }) => theme.responsive.small}) {
      padding: 120px 6px 24px 6px;

      &__card {
        &__content {
          padding: 110px 16px 44px 16px;
        }
      }
    }
  }
`;
