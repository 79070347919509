import styled from 'styled-components';

export const StyledHomePage = styled.div`
  width: 100%;

  .dp-home-page {
    width: 100%;
    height: 100%;
    flex-direction: column;

    &__main-section {
      width: 100%;
      min-height: 100vh;
    }

    &__secondary-section {
      width: 100%;
      height: 100%;
    }
  }
`;
