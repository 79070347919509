import styled from 'styled-components';

export const StyledFooterSection = styled.div`
  width: 100%;

  .dp-footer-section {
    width: 100%;
    padding: 64px;
    background-color: ${({ theme }) => theme.colors.black};
    justify-content: center;
    align-items: center;

    &__section {
      width: fit-content;
      padding: 16px 80px;
      height: 100%;
      border-right: 1px solid ${({ theme }) => theme.colors.hibiscus};

      &__contact {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
      }

      &__nav {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;

        &__link {
          background-image: linear-gradient(
            to right,
            ${({ theme }) => theme.colors.hibiscus},
            ${({ theme }) => theme.colors.hibiscus} 50%,
            ${({ theme }) => theme.colors.white} 50%
          );
          background-size: 200% 100%;
          background-position: -100%;
          padding: 5px 0;
          cursor: pointer;
          width: fit-content;
          display: inline-block;
          position: relative;
          background-clip: unset;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          transition: all 0.3s ease-in-out;

          &:before {
            content: '';
            background: ${({ theme }) => theme.colors.hibiscus};
            display: block;
            position: absolute;
            bottom: -3px;
            left: 0;
            width: 0;
            height: 3px;
            transition: all 0.3s ease-in-out;
          }

          &:hover {
            background-position: 0;

            &:before {
              width: 100%;
            }
          }
        }
      }

      &:last-child {
        border: none;
      }
    }

    @media (max-width: ${({ theme }) => theme.responsive.highres}) {
      padding: 60px 40px;

      &__section {
        padding: 16px 60px;
      }
    }

    @media (max-width: ${({ theme }) => theme.responsive.desktop}) {
      padding: 40px 0;
    }

    @media (max-width: ${({ theme }) => theme.responsive.tablet}) {
      flex-direction: column;
      padding: 16px;

      &__section {
        padding: 40px 16px;
        border-bottom: 1px solid ${({ theme }) => theme.colors.hibiscus};
        border-right: none;
      }
    }

    @media (max-width: ${({ theme }) => theme.responsive.small}) {
      padding: 16px 0;

      &__section {
        padding: 30px 10px;
      }
    }
  }
`;
