// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useCallback } from 'react';
import Gallery from 'react-photo-gallery';
import { Image } from 'antd';

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { Heading1 } from '@Theme/theme-provider/typography';

// -------------------------------------------------------------------------------
// Styles and Assets
// -------------------------------------------------------------------------------
import laQuilombera from '@Theme/assets/works/la-quilombera.webp';
import spiderman from '@Theme/assets/works/spiderman.webp';
import cajero1 from '@Theme/assets/works/cajero1.webp';
import cajero2 from '@Theme/assets/works/cajero2.webp';
import agencia1 from '@Theme/assets/works/agencia1.webp';
import la29Rotulo1 from '@Theme/assets/works/la29-rotulo1.webp';
import la29 from '@Theme/assets/works/la29.webp';
import biciZone from '@Theme/assets/works/bici-zone.webp';
import burgerHouse from '@Theme/assets/works/burger-house.webp';
import cajas from '@Theme/assets/works/cajas.webp';
import familia from '@Theme/assets/works/familia.webp';
import happyGringo from '@Theme/assets/works/happy-gringo.webp';
import local1 from '@Theme/assets/works/local1.webp';
import local2 from '@Theme/assets/works/local2.webp';
import multiple1 from '@Theme/assets/works/multiple1.webp';
import multiple2 from '@Theme/assets/works/multiple2.webp';
import multiple3 from '@Theme/assets/works/multiple3.webp';
import multiple4 from '@Theme/assets/works/multiple4.webp';
import multiple5 from '@Theme/assets/works/multiple5.webp';
import cuadernos1 from '@Theme/assets/works/cuadernos1.webp';
import dp1 from '@Theme/assets/works/dp1.webp';
import dp2 from '@Theme/assets/works/dp2.webp';
import dp3 from '@Theme/assets/works/dp3.webp';
import azurmed from '@Theme/assets/works/azurmed.webp';
import dentallive from '@Theme/assets/works/dentallive.webp';
import curvy from '@Theme/assets/works/curvy.webp';
import meldental from '@Theme/assets/works/meldental.webp';
import lumos from '@Theme/assets/works/lumos.webp';
import lumos1 from '@Theme/assets/works/lumos1.webp';
import pizza from '@Theme/assets/works/pizza.webp';
import pacman from '@Theme/assets/works/pacman.webp';
import magickids from '@Theme/assets/works/magickids.webp';
import judicatura from '@Theme/assets/works/judicatura.webp';
import { StyledWorksSection } from './style';

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const WorksSection = () => {
  const imageRenderer = useCallback(({ index, photo: { src, height, width } }) => {
    return <Image width={width} height={height} src={src} key={`word-done-${index}`} alt="work done" />;
  }, []);

  return (
    <StyledWorksSection>
      <div id="works-section" className="dp-works-section">
        <div className="dp-works-section__header">
          <Heading1>Trabajos</Heading1>
        </div>
        <div className="dp-works-section__body">
          <Gallery
            renderImage={imageRenderer}
            photos={[
              { src: laQuilombera, width: 10, height: 5 },
              { src: cajero1, width: 10, height: 12 },
              { src: la29Rotulo1, width: 4, height: 4 },
              { src: cajero2, width: 10, height: 12 },
              { src: agencia1, width: 9, height: 12 },
              { src: burgerHouse, width: 8, height: 5 },
              { src: biciZone, width: 9, height: 12 },
              { src: cajas, width: 10, height: 12 },
              { src: familia, width: 12, height: 10 },
              { src: happyGringo, width: 12, height: 10 },
              { src: local1, width: 10, height: 12 },
              { src: local2, width: 12, height: 10 },
              { src: multiple1, width: 10, height: 12 },
              { src: multiple2, width: 10, height: 10 },
              { src: multiple3, width: 10, height: 10 },
              { src: multiple4, width: 10, height: 10 },
              { src: multiple5, width: 10, height: 10 },
              { src: cuadernos1, width: 10, height: 13 },
              { src: dp3, width: 6, height: 2 },
              { src: dp1, width: 12, height: 10 },
              { src: dp2, width: 6, height: 2 },
              { src: spiderman, width: 10, height: 14 },
              { src: la29, width: 10, height: 14 },
              { src: azurmed, width: 10, height: 10 },
              { src: dentallive, width: 10, height: 12 },
              { src: curvy, width: 10, height: 6 },
              { src: meldental, width: 10, height: 10 },
              { src: lumos, width: 8, height: 14 },
              { src: lumos1, width: 10, height: 6 },
              { src: pizza, width: 10, height: 9 },
              { src: pacman, width: 10, height: 14 },
              { src: magickids, width: 10, height: 6 },
              { src: judicatura, width: 10, height: 14 },
            ]}
          />
        </div>
      </div>
    </StyledWorksSection>
  );
};

export default WorksSection;
