import styled from 'styled-components';

export const StyledHeader = styled.div`
  width: 100%;
  height: 100px;

  .dp-header {
    width: 100%;
    padding: 14px 24px;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.concrete};

    &__logo {
      width: 200px;
      height: 100%;

      svg {
        max-height: 100%;
      }
    }

    &__menu {
      &__icon {
        cursor: pointer;
        width: 26px;
        min-height: 20px;
        gap: 4px;
        background: transparent;
        display: flex;
        flex-direction: column;
        transition: all 0.1s ease-out;
        border: none;
        background: none transparent;
        padding: 0;

        &:focus-visible {
          border: none;
          background: none transparent;
          outline: none;
        }

        &__bar1,
        &__bar2,
        &__bar3 {
          width: 100%;
          height: 4px;
          background-color: ${({ theme }) => theme.colors.hibiscus};
        }

        &__bar1 {
          &--open {
            transform: rotate(0deg) translateY(6px);
          }
        }

        &__bar2 {
          &--open {
            opacity: 0;
          }
        }

        &__bar3 {
          &--open {
            transform: rotate(-90deg) translateX(5px);
          }
        }

        &:hover {
          gap: 6px;
        }

        &--open {
          transform: rotate(-45deg);
          gap: 2px;

          &:hover {
            gap: 2px;
          }
        }
      }
    }
  }
`;
