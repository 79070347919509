// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------
import { setMainMenuOpen } from '@Store/slices/app-state/app-state-slice';
import { selectMainMenuOpen } from '@Store/selectors';

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { Paragraph1 } from '@Theme';

// -------------------------------------------------------------------------------
// Styles and Assets
// -------------------------------------------------------------------------------
import { StyledMainMenu } from './style';

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const MainMenu = () => {
  const dispatch = useDispatch();
  const menuOpen = useSelector(selectMainMenuOpen);

  const onMenuItemClick = useCallback(() => {
    dispatch(setMainMenuOpen(!menuOpen));
  }, [dispatch, menuOpen]);

  return (
    <StyledMainMenu>
      <nav className="dp-main-menu">
        <a href="#who-we-are-section" className="dp-main-menu__link" onClick={onMenuItemClick}>
          <Paragraph1>Quienes Somos</Paragraph1>
        </a>
        <a href="#services-section" className="dp-main-menu__link" onClick={onMenuItemClick}>
          <Paragraph1>Servicios</Paragraph1>
        </a>
        <a href="#clients-section" className="dp-main-menu__link" onClick={onMenuItemClick}>
          <Paragraph1>Clientes</Paragraph1>
        </a>
        <a href="#products-section" className="dp-main-menu__link" onClick={onMenuItemClick}>
          <Paragraph1>Productos</Paragraph1>
        </a>
        <a href="#works-section" className="dp-main-menu__link" onClick={onMenuItemClick}>
          <Paragraph1>Trabajos</Paragraph1>
        </a>
        <a href="#contact-section" className="dp-main-menu__link" onClick={onMenuItemClick}>
          <Paragraph1>Contacto</Paragraph1>
        </a>
      </nav>
    </StyledMainMenu>
  );
};

export default MainMenu;
