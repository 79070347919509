// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from 'react';

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------

// -------------------------------------------------------------------------------
// Styles and Assets
// -------------------------------------------------------------------------------
import { StyledScrollDown } from './style';

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const ScrollDown = () => {
  return (
    <StyledScrollDown>
      <div className="dp-scroll-down">
        <svg className="dp-scroll-down__svg">
          <path className="dp-scroll-down__svg__arrow dp-scroll-down__svg__arrow--1" d="M0 0 L30 32 L60 0" />
          <path className="dp-scroll-down__svg__arrow dp-scroll-down__svg__arrow--2" d="M0 20 L30 52 L60 20" />
          <path className="dp-scroll-down__svg__arrow dp-scroll-down__svg__arrow--3" d="M0 40 L30 72 L60 40" />
        </svg>
      </div>
    </StyledScrollDown>
  );
};

export default ScrollDown;
