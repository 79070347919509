import styled from 'styled-components';

export const StyledScrollDown = styled.div`
  .dp-scroll-down {
    &__svg {
      width: 60px;
      height: 72px;

      &__arrow {
        stroke: ${({ theme }) => theme.colors.hibiscus};
        fill: transparent;
        stroke-width: 1px;
        animation: arrow 2s infinite;
        -webkit-animation: arrow 2s infinite;

        &--1 {
          animation-delay: -1s;
        }

        &--2 {
          animation-delay: -0.5s;
        }

        &--3 {
          animation-delay: 0s;
        }
      }
    }

    @keyframes arrow {
      0% {
        opacity: 0;
      }
      40% {
        opacity: 1;
      }
      80% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }
  }
`;
