import styled from 'styled-components';

export const StyledWorksSection = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};

  .dp-works-section {
    padding: 60px;
    gap: 80px;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;

    &__header {
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    &__body {
      max-width: calc(100vw - 120px);
      width: 100%;
      display: block;
    }
  }

  @media (max-width: ${({ theme }) => theme.responsive.tablet}) {
    .dp-works-section {
      padding: 20px;

      &__body {
        max-width: calc(100vw - 40px);
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.responsive.tablet}) {
    .dp-works-section {
      padding: 6px;
      gap: 24px;

      &__body {
        max-width: calc(100vw - 12px);
      }
    }
  }
`;
