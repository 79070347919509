import styled from 'styled-components';

export const StyledContactSection = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.concrete};

  .dp-contact-section {
    width: 100%;
    padding: 60px;
    gap: 60px;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;

    &__header {
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    &__body {
      gap: 60px;

      &__animation,
      &__form {
        width: 50%;
        align-items: center;
        justify-content: center;
        transition: all 0.8s ease-in-out;
      }

      &__animation {
        &--formSent {
          width: 100%;
        }

        svg {
          max-width: 800px;
        }
      }

      &__form {
        .ant-form {
          width: 100%;

          .ant-form-item {
            .ant-form-item-row {
              width: 100%;

              .ant-form-item-label {
                label {
                  color: ${({ theme }) => theme.colors.hibiscus};
                }
              }

              input,
              textarea {
                font-family: 'Roboto Mono', monospace;
                font-size: 16px;
                resize: none;
              }

              button {
                padding: 12px 32px;
                height: auto;
                width: auto;
                background-color: ${({ theme }) => theme.colors.hibiscus};
              }
            }
          }
        }
      }
    }

    @media (max-width: ${({ theme }) => theme.responsive.tablet}) {
      padding: 40px;

      &__body {
        gap: 40px;
        flex-direction: column;

        &__animation,
        &__form {
          width: 100%;
        }

        &__form {
          .ant-form {
            .ant-form-item {
              .ant-form-item-row {
                .ant-form-item-control,
                .ant-form-item-label {
                  flex: auto;
                }

                .ant-form-item-control-input-content {
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }

    @media (max-width: ${({ theme }) => theme.responsive.small}) {
      padding: 20px;
      gap: 20px;

      &__body {
        gap: 20px;
      }
    }
  }
`;
