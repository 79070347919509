import styled from 'styled-components';

export const StyledClientsSection = styled.div`
  width: 100%;

  .dp-clients-section {
    width: 100%;
    padding: 60px;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.concrete};

    &__header {
      text-align: center;
    }

    &__body {
      max-width: 1440px;
      flex-wrap: wrap;
      margin: 120px auto 16px auto;
      align-items: center;
      justify-content: center;

      &__card {
        max-width: 140px;
        align-items: center;
        justify-content: center;
        margin: 0 16px;

        &__image {
          width: 100%;
          height: auto;
        }
      }
    }

    @media (max-width: ${({ theme }) => theme.responsive.tablet}) {
      padding: 40px;

      &__body {
        gap: 24px;
        margin: 60px auto 16px auto;

        &__card {
          &--hideMobile {
            display: none;
          }
        }
      }
    }

    @media (max-width: ${({ theme }) => theme.responsive.mobile}) {
      padding: 40px 24px;

      &__body {
        margin: 44px auto 16px auto;
      }
    }

    @media (max-width: ${({ theme }) => theme.responsive.small}) {
      padding: 20px 0;

      &__header {
        padding: 0 16px;
      }
    }
  }
`;
