// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from 'react';
import { Card, Image } from 'antd';

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { Heading1, Heading2, Paragraph3 } from '@Theme/theme-provider/typography';

// -------------------------------------------------------------------------------
// Styles and Assets
// -------------------------------------------------------------------------------
import acrylicCharacter from '@Theme/assets/images/acrylic-character.webp';
import stainlessSteel from '@Theme/assets/images/stainless-steel.webp';
import pvc from '@Theme/assets/images/pvc.webp';
import { StyledSignsSection } from './style';

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const SignsSection = () => {
  return (
    <StyledSignsSection>
      <div id="signs-section" className="dp-signs-section">
        <div className="dp-signs-section__header">
          <Heading1>Rótulos</Heading1>
        </div>
        <div className="dp-signs-section__cards">
          <Card
            hoverable
            className="dp-signs-section__cards__card"
            cover={<Image width={350} height={350} src={acrylicCharacter} alt="Letra 3D" />}
          >
            <div className="dp-signs-section__cards__card__body">
              <div className="dp-signs-section__cards__card__body__title">
                <Heading2>Letras 3D en Acrílico</Heading2>
              </div>
              <div className="dp-signs-section__cards__card__body__content">
                <Paragraph3>
                  Las letras en acrílico 3D son una excelente opción para rótulos publicitarios con letras individuales
                  debido a que ofrecen una apariencia llamativa y personalizada. Cada letra es cortada individualmente
                  en acrílico, lo que permite crear diseños únicos y adaptados a las necesidades específicas de la marca
                  o negocio.
                </Paragraph3>
                <Paragraph3>
                  Además, las letras en acrílico 3D pueden ser personalizadas en diferentes tamaños, fuentes y colores,
                  lo que permite que se adapten a la imagen de marca de la empresa y a sus necesidades publicitarias.
                  Esto permite una mayor flexibilidad en el diseño y permite que las letras se destaquen aún más.
                </Paragraph3>
                <Paragraph3>
                  Otra ventaja de las letras en acrílico 3D para rótulos publicitarios con letras individuales es que
                  ofrecen una mayor visibilidad y legibilidad, lo que permite que el mensaje publicitario se transmita
                  de manera clara y efectiva. Al estar hechas de acrílico, son duraderas y resistentes, lo que garantiza
                  que la señalización publicitaria permanezca legible y atractiva durante un largo periodo de tiempo.
                </Paragraph3>
              </div>
            </div>
          </Card>
          <Card
            hoverable
            className="dp-signs-section__cards__card"
            cover={<Image width={350} height={350} src={stainlessSteel} alt="Letra 3D" />}
          >
            <div className="dp-signs-section__cards__card__body">
              <div className="dp-signs-section__cards__card__body__title">
                <Heading2>Letras 3D en Acero Inoxidable</Heading2>
              </div>
              <div className="dp-signs-section__cards__card__body__content">
                <Paragraph3>
                  Durabilidad: El acero inoxidable es un material muy resistente y duradero, capaz de soportar las
                  condiciones climáticas extremas, la humedad y el desgaste del uso diario. Esto significa que las
                  letras 3D en acero inoxidable tienen una vida útil prolongada y requieren poco mantenimiento.
                </Paragraph3>
                <Paragraph3>
                  Atractivas y profesionales: Las letras 3D en acero inoxidable tienen un aspecto moderno, elegante y
                  profesional que atrae la atención de los clientes potenciales. El acero inoxidable tiene un acabado
                  pulido y brillante que crea un efecto de alto contraste que hace que las letras se destaquen.
                </Paragraph3>
                <Paragraph3>
                  Fácil instalación: Las letras 3D en acero inoxidable son fáciles de instalar en una variedad de
                  superficies, incluyendo paredes, vidrio y metal. Además, se pueden instalar con soportes ocultos para
                  crear un efecto flotante y atractivo.
                </Paragraph3>
              </div>
            </div>
          </Card>
          <Card
            hoverable
            className="dp-signs-section__cards__card"
            cover={<Image width={350} height={350} src={pvc} alt="Letra 3D" />}
          >
            <div className="dp-signs-section__cards__card__body">
              <div className="dp-signs-section__cards__card__body__title">
                <Heading2>Letras 3D en PVC</Heading2>
              </div>
              <div className="dp-signs-section__cards__card__body__content">
                <Paragraph3>
                  Peso ligero: El PVC es un material ligero en comparación con otros materiales de letras 3D como el
                  metal, lo que hace que sea más fácil de instalar y manejar.
                </Paragraph3>
                <Paragraph3>
                  Durabilidad: El PVC es un material resistente que puede soportar la exposición a la intemperie y a los
                  elementos, lo que lo hace ideal para su uso en rótulos publicitarios al aire libre.
                </Paragraph3>
                <Paragraph3>
                  Versatilidad: El PVC se puede cortar en una variedad de formas y tamaños, lo que permite a los
                  diseñadores de rótulos crear letras y logotipos personalizados que se adapten a sus necesidades.
                </Paragraph3>
                <Paragraph3>
                  Fácil de pintar: El PVC se puede pintar fácilmente para que coincida con los colores y la estética de
                  la marca, lo que lo hace ideal para rótulos publicitarios personalizados.
                </Paragraph3>
                <Paragraph3>
                  Costo efectivo: El PVC es un material asequible en comparación con otros materiales de letras 3D como
                  el metal, lo que lo hace una opción atractiva para los propietarios de negocios y diseñadores gráficos
                  que buscan opciones rentables de rótulos publicitarios.
                </Paragraph3>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </StyledSignsSection>
  );
};

export default SignsSection;
