// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from 'react';
import { Card } from 'antd';
import Lottie from 'react-lottie';

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { Heading1, Heading2 } from '@Theme';
import { List } from '@Components/common/list';

// -------------------------------------------------------------------------------
// Styles and Assets
// -------------------------------------------------------------------------------
import * as designer from '@Helpers/animations/designer.json';
import * as manPrinting from '@Helpers/animations/man-printing.json';
import * as printer from '@Helpers/animations/printer.json';
import * as photoEditingAnimation from '@Helpers/animations/photo-editing-animation.json';
import * as salesMan from '@Helpers/animations/sales-man.json';
import { StyledServicesSection } from './style';

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const ServicesSection = () => {
  return (
    <StyledServicesSection>
      <div id="services-section" className="dp-services-section">
        <div className="dp-services-section__header">
          <Heading1>Nuestros Servicios</Heading1>
        </div>
        <div className="dp-services-section__cards">
          <Card
            hoverable
            className="dp-services-section__cards__card"
            cover={
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: designer,
                }}
              />
            }
          >
            <div className="dp-services-section__cards__card__body">
              <div className="dp-services-section__cards__card__body__title">
                <Heading2>Comunicación Promocional</Heading2>
              </div>
              <div className="dp-services-section__cards__card__body__content">
                <List
                  items={[
                    'Campañas de comunicación',
                    'Estrategias comunicacionales',
                    'Artes gráficas',
                    'Publicidad física',
                    'Imagen corporativa',
                    'Gestión de espacios',
                    'Marketing Directo',
                  ]}
                />
              </div>
            </div>
          </Card>
          <Card
            hoverable
            className="dp-services-section__cards__card"
            cover={
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: manPrinting,
                }}
              />
            }
          >
            <div className="dp-services-section__cards__card__body">
              <div className="dp-services-section__cards__card__body__title">
                <Heading2>Productos Promocionales</Heading2>
              </div>
              <div className="dp-services-section__cards__card__body__content">
                <List
                  items={[
                    'Diseño',
                    'Formatos especiales',
                    'PLV',
                    'Eventos',
                    'Creación de stands',
                    'Decoración de interiores',
                    'Publicidad y Modelos',
                    'Impresión de direcciones',
                    'Envío seguimiento y control',
                  ]}
                />
              </div>
            </div>
          </Card>
          <Card
            hoverable
            className="dp-services-section__cards__card"
            cover={
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: printer,
                }}
              />
            }
          >
            <div className="dp-services-section__cards__card__body">
              <div className="dp-services-section__cards__card__body__title">
                <Heading2>Cartelería</Heading2>
              </div>
              <div className="dp-services-section__cards__card__body__content">
                <List
                  items={[
                    'Impresiones para interior y exterior',
                    'Expositores, displays y P.L.V.',
                    'Banderolas',
                    'Sistemas de exhibición promocional',
                  ]}
                />
              </div>
            </div>
          </Card>
          <Card
            hoverable
            className="dp-services-section__cards__card"
            cover={
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: photoEditingAnimation,
                }}
              />
            }
          >
            <div className="dp-services-section__cards__card__body">
              <div className="dp-services-section__cards__card__body__title">
                <Heading2>Imagen Digital</Heading2>
              </div>
              <div className="dp-services-section__cards__card__body__content">
                <List
                  items={[
                    'Creaciones, retoques y manipulación de la imagen.',
                    'Grabación CD',
                    'Impresión Fotográfica',
                    'Impresiones Digitales Formatos múltiples A3, A4, A5',
                  ]}
                />
              </div>
            </div>
          </Card>
          <Card
            hoverable
            className="dp-services-section__cards__card"
            cover={
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: salesMan,
                }}
              />
            }
          >
            <div className="dp-services-section__cards__card__body">
              <div className="dp-services-section__cards__card__body__title">
                <Heading2>Articulos Promocionales</Heading2>
              </div>
              <div className="dp-services-section__cards__card__body__content">
                <List
                  items={[
                    'Botones Magnéticos',
                    'Camisetas y gorras',
                    'Mouse Pads',
                    'Individuales',
                    'Toma todo',
                    'Freesbee 25cm',
                    'Packing para obsequios especiales',
                    'Invitaciones',
                    'Tarjetas, dossier, catálogos, etc.',
                  ]}
                />
              </div>
            </div>
          </Card>
        </div>
      </div>
    </StyledServicesSection>
  );
};

export default ServicesSection;
