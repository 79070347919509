import styled from 'styled-components';

export const StyledList = styled.div`
  .dp-list {
    margin: 0;
    padding-left: 0;

    &__li {
      position: relative;
      list-style-type: none;
      padding-left: 24px;
      margin-bottom: 8px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 4px;
        top: -1px;
        width: 5px;
        height: 11px;
        border-width: 0 2px 2px 0;
        border-style: solid;
        border-color: ${({ theme }) => theme.colors.hibiscus};
        transform-origin: bottom left;
        transform: rotate(45deg);
      }
    }
  }
`;
