// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useEffect, useRef } from 'react';
import { Spin, Card } from 'antd';
import { Wrapper } from '@googlemaps/react-wrapper';
import Lottie from 'react-lottie';

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------

// -------------------------------------------------------------------------------
// Styles and Assets
// -------------------------------------------------------------------------------
import * as location from '@Helpers/animations/location.json';
import { StyledLocationSection } from './style';

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const MapComponent = () => {
  const ref = useRef();

  useEffect(() => {
    if (window.google) {
      const position = {
        lat: -0.242786,
        lng: -78.526708,
      };

      const map = new window.google.maps.Map(ref.current, {
        mapId: 'a3e1924060c222f8',
        center: position,
        zoom: 14,
      });

      new window.google.maps.Marker({
        position,
        map,
      });
    }
  });

  return (
    <>
      <div ref={ref} id="map" />
    </>
  );
};

const LocationSection = () => {
  return (
    <StyledLocationSection>
      <div id="location-section" className="dp-location-section">
        <div className="dp-location-section__header">
          <div className="dp-location-section__header__animation">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: location,
              }}
            />
          </div>
        </div>
        <div className="dp-location-section__body">
          <Card hoverable className="dp-location-section__body__card">
            <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY} render={() => <Spin />}>
              <MapComponent />
            </Wrapper>
          </Card>
        </div>
      </div>
    </StyledLocationSection>
  );
};

export default LocationSection;
